import BaseBean from "@/utils/BaseBean";

export interface IMemberInfoListDataObj {
    utilInst:MemberInfoListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class MemberInfoListUtil extends BaseBean{
    public dataObj:IMemberInfoListDataObj

    constructor(proxy:any,dataObj:IMemberInfoListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}