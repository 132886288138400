import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import MemberActionListUtil,{IMemberActionListDataObj} from './memberActionListUtil';
export default defineComponent ({
    name: 'MemberActionList',
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IMemberActionListDataObj=reactive<IMemberActionListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            disabled:false,
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.ToolsProviderApi.buildUrl('/memberAction/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new MemberActionListUtil(proxy,dataObj);
            if(props.engineParams && props.engineParams.phone){
                dataObj.disabled=true;
                dataObj.pageList.queryParam.phone=props.engineParams.phone;
            }
        })
        onMounted(()=>{

        })
        //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params:any) => {
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'增加'},{value:1,label:'减少'}]
                    }
                }
            })

        return{
            ...toRefs(dataObj),comboSelect
        }
    }
});