import BaseBean from "@/utils/BaseBean";

export interface IMemberActionListDataObj {
    utilInst:MemberActionListUtil
    refMap:Map<string,any>
    disabled:boolean
    pageListRef:any
    pageList: any
}

export default class MemberActionListUtil extends BaseBean{
    public dataObj:IMemberActionListDataObj

    constructor(proxy:any,dataObj:IMemberActionListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}