import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import MemberInfoListUtil,{IMemberInfoListDataObj} from './memberInfoListUtil';
import MemberActionList from "@/views/project/tools/score/memberAction/MemberActionList.vue";
export default defineComponent ({
    name: 'MemberInfoList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IMemberInfoListDataObj=reactive<IMemberInfoListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelMethod: utils.ToolsProviderApi.buildUrl('/memberInfo/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new MemberInfoListUtil(proxy,dataObj);
        })
        onMounted(()=>{

        })
        //---------------------------computed------------
        const detailHandler=async (row:any)=>{
            await utils.UtilPub.openDialog({title: row.F_NAME+"积分增减明细",modelComp:MemberActionList,proxy:proxy,ownerComp:dataObj.pageListRef,
                phone:row['F_PHONE'],showFooterBtn:false,fullscreen:true,modelType:'list'})
        }
        return{
            ...toRefs(dataObj),detailHandler
        }
    }
});